
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.global-search-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  .global-search-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #333;
    padding-top: 250px;
    opacity: 0.3;
  }
  .global-search-container {
    position: fixed;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    opacity: 1;
    z-index: 1002;
  }
  @media (max-width: 1200px) {
    .global-search-container {
      width: 350px;
    }
  }
  @media (max-width: 750px) {
    .global-search-container {
      width: 300px;
    }
  }
  @media (max-width: 576px) {
    .global-search-container {
      width: 200px;
    }
  }
  .global-search-result-group {
    border-bottom: 1px solid $font-100;
  }
  .global-search-result-group:last-child {
    border-bottom: none;
  }
  .global-search-result-container {
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    max-height: 50vh;
    overflow-y: auto;
  }
  .global-search-result-item {
    height: 22px;
    line-height: 22px;
    overflow: hidden;
    &.pointer {
      cursor: pointer;
    }
    &.disable {
      cursor: not-allowed;
    }
    .name {
      flex-shrink: 0;
    }
    &.recent {
      &:hover {
        background: #f6f9fb;
      }
    }
  }
}

.animate-in-top {
  animation: InTop 0.3s;
}

.animate-out-top {
  animation: InTop 0.3s reverse;
}

@keyframes InTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
