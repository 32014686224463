
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.header-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $zindex-fixed;

  .navbar {
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    min-height: 56px;
    @include media-breakpoint-up(lg) {
      // Fix IE 11 bug
      &:after {
        content: '';
        min-height: inherit;
        font-size: 0;
      }
    }
  }

  .navbar-logo-img {
    max-height: 56px;
    width: auto;
    font-size: 14px;
    overflow: hidden;
  }

  .navbar-bg-color {
    background-color: $theme-blue;
  }

  .navbar-brand {
    margin-right: 16px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    .navbar-brand-profile {
      display: flex;
      align-items: center;
    }
    .navbar-brand-content {
      display: flex;
      align-items: center;
      color: $white;
      line-height: 56px;

      &:focus {
        outline: auto;
      }
    }
  }
  .navbar-brand-mobile {
    display: flex;
    justify-content: space-between;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    .navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar {
      flex-direction: column;
      // padding-bottom: 8px;

      .navbar-brand {
        margin: 0;
        padding: 16px 2px;
      }

      .navbar-collapse {
        flex-direction: column;
      }

      .navbar-right {
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
