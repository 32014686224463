
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

:deep() {
  .s-loading-wrap {
    > div {
      margin-top: 0 !important;
    }
  }
}
.selected {
  padding: 0 12px;
  cursor: not-allowed;
  font-style: italic;
  font-size: 14px;
  color: #4d627b;
}
