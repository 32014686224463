
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.login-container {
  width: 95%;
  margin-top: 25vh;
}
@include media-breakpoint-between(md, lg) {
  .login-container {
    width: 80%;
  }
}
@include media-breakpoint-between(lg, xl) {
  .login-container {
    width: 60%;
  }
}
@include media-breakpoint-up(xl) {
  .login-container {
    width: 40%;
  }
}
