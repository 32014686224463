
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.search-group-box {
  flex: 1;
  position: relative;
  :deep(.input-group-append) {
    background: transparent;
    border: none;
  }
  .search-icon,
  .clear-icon {
    color: rgba(0, 0, 0, 0.5);
    background: #ffffff;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 8px;
  }
  .search-icon {
    left: 8px;
  }
  .clear-icon {
    right: 8px;
  }
  .search-input {
    background: $white;
    padding: 6px 36px;
  }
}
