
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.search-result-container {
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  max-height: 50vh;
  overflow-y: auto;
  .text__highlight {
    color: #df3030;
    background: transparent;
  }
  .search-result-group-item {
    padding: 0 0 16px;
    &:last-child {
      padding: 0;
    }
  }
  .group-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: $font-500;
    .group-toggle-button {
      margin: 0 0 0 8px;
      padding: 0 !important;
      color: $font-400 !important;
      transition: all 0.3s;
      transform: rotate(0deg);
      &.rotate-90 {
        transform: rotate(-90deg);
      }
    }
  }
  .result-list {
    .result-item {
      border: 1px solid $border-200;
      padding: 8px 16px;
      margin: 8px 0px;
      border-radius: 5px;
      a {
        text-decoration: none;
        display: block;
      }
      .result-title {
        font-weight: bold;
        color: $font-500;
        line-height: 24px;
      }
      .result-status {
        font-size: 12px;
        font-weight: 400;
        color: $font-300;
        line-height: 24px;
      }
      .assets-list {
        margin: 8px 0 0;
      }
      .assets-number {
        font-size: 12px;
        color: $font-400;
        margin: 0 8px 8px 0;
      }
      .toggle-assets-btn {
        font-size: 12px;
        color: $font-400;
        height: 24px;
        margin: -8px 0 0;
      }
      .result-contents {
        margin: 4px 0 0;
        height: auto !important;
        font-size: 14px;
        line-height: 22px;
        color: $font-400 !important;
        padding: 0 !important;
        text-align: left !important;
      }
      .toggle-content-btn {
        padding: 0;
        font-size: 14px;
      }
      .none-content-router {
        text-decoration: none;
      }
    }
  }
}
