
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

:deep(.banner-notification__content) {
  :first-child {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  :last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
