
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.error-title {
  margin: 0;
}
.error-info {
  font-size: 14px;
  color: #4d627b;
  background: #eee;
  border-radius: 3px;
  padding: 4px 8px;
  margin-bottom: 4px;
  margin-top: 8px;
}
