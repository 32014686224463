
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.global-search-history {
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  max-height: 50vh;
  overflow-y: auto;
  .search-history-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
