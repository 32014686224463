.app-wrap div.center-card-layout {
  @include media-breakpoint-up(md) {
    max-width: 500px;
    &.more-width {
      max-width: 800px;
    }
  }
  .page-title {
    font-size: 24px;
    text-align: center;
  }
  .sub-title {
    font-size: 20px;
    line-height: 22px;
    margin: 10px 0;
  }
  .s-card .s-card-body {
    padding: 32px;
  }
  // hide *
  .no-sup .s-form-group .s-form-label sup {
    display: none !important;
  }
  .no-feedback .feedback {
    display: none;
  }
  .s-form-group {
    height: 80px;
  }
  .submit-btn {
    width: 100%;
  }
  .select-type-list-wrap {
    border: solid 1px $border;
    border-radius: 3px;
    padding: 10px 16px;
    display: flex;
    transition: all 0.2s;
    &:hover {
      border-color: $theme-blue-400;
      background-color: $background-200;
      cursor: pointer;
    }
  }
  .checkbox-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-flow: column;
    }
  }

  .warning-title {
    font-size: 24px;
    font-weight: bolder;
    line-height: 28px;
  }
  .warning-sub-info {
    font-size: 16px;
    line-height: 24px;
  }
}
.tooltip-wrapper-in-center-card {
  max-width: 350px;
}

.text-right {
  text-align: right;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 16px;
}
.font-size-20 {
  font-size: 20px;
}
.font-color-light1 {
  color: $font-100;
}
.font-weight-700 {
  font-weight: 700;
}
.font-color-light2 {
  color: $font-200;
}
.font-color-light4 {
  color: $font-400;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  &.border-color-light1 {
    border-top: 1px solid $border-100;
  }
  &.border-color-light2 {
    border-top: 1px solid $border-200;
  }
  &.border-color-light3 {
    border-top: 1px solid $border-300;
  }
}
