
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

// for accessbility
.portal-footer-content {
  overflow: hidden;
  :deep(a) {
    text-decoration: underline;
  }
}
