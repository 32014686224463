
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.branding-wrap {
  .branding-logo-wrap {
    width: 100px;
    height: 20px;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
