// Font-family
$roboto-font-family: 'Roboto' !default;
$roboto-font-light: '../fonts/Roboto-Light.ttf' !default;
$roboto-font-regular: '../fonts/Roboto-Regular.ttf' !default;
$roboto-font-medium: '../fonts/Roboto-Regular.ttf' !default;
$roboto-font-bold: '../fonts/Roboto-Bold.ttf' !default;

@font-face {
  font-family: $roboto-font-family;
  font-weight: 300;
  src: url($roboto-font-light);
}

@font-face {
  font-family: $roboto-font-family;
  font-weight: 400;
  src: url($roboto-font-regular);
}

@font-face {
  font-family: $roboto-font-family;
  font-weight: 500;
  src: url($roboto-font-medium);
}

@font-face {
  font-family: $roboto-font-family;
  font-weight: 700;
  src: url($roboto-font-bold);
}

body,
html {
  font-family: $roboto-font-family, sans-serif;
}
