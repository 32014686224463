
            @import "@starlight-bd/frontend-library/src/style/style.scss";
            

.app-wrap {
  min-height: 100vh;
  overflow: hidden;
}

.top-lever-title {
  position: absolute;
  top: -999em;
}
.full-screen-loading {
  background-color: rgba(255, 255, 255, 1);
  .sk-folding-cube .sk-cube:before {
    background-color: $primary;
  }
}
