html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  font-size: rem(16);
  -webkit-appearance: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  line-height: 1.61em;
  background-color: $background-100;
}

a {
  color: $theme-blue;
  // text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &[disabled] {
    color: #8092ac;
    cursor: not-allowed;
    text-decoration: none;
  }

  &:focus {
    outline: auto !important;
  }
}

dd {
  word-break: break-word;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

// nprogress modify style
#nprogress .bar {
  background: #fcb314 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #fcb314, 0 0 5px #fcb314;
}



#nprogress .spinner-icon {
  border-top-color: #fcb314;
  border-left-color: #fcb314;
}